<template>
  <section id="Valores" class="moralValues">
    <div class="__content">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> Español </span>
        </h2>
      </div>
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
        type="text"
        v-model="dataES.title"
      />

      <input
        class="form-control wet-form text-center"
        type="text"
        v-model="dataES.description"
      />
      <div class="__cards_container">
        <div
          class="shadow ___values_card"
          v-for="card in dataES.cards"
          :key="card.id"
        >
          <img
            :src="require(`../../assets/${card.img}`)"
            alt="Imagen detectar (mobile)"
            loading="lazy"
          />

          <div class="hoverLabel" :style="{ backgroundColor: card.color }">
            <input
              class="form-control form-control-lg wet-form fw-bold text-center mb-2"
              type="text"
              v-model="card.title"
            />

            <textarea
              class="form-control wet-form text-center mb-2"
              rows="5"
              type="text"
              v-model="card.description"
            />
          </div>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-start ms-8">
        <button
          class="btn btn-success btn-lg mt-3 mb-3"
          @click="syncContent('ES')"
        >
          Guardar
        </button>
      </div>
    </div>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="__content">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> English </span>
        </h2>
      </div>
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
        type="text"
        v-model="dataEN.title"
      />

      <input
        class="form-control wet-form text-center"
        type="text"
        v-model="dataEN.description"
      />
      <div class="__cards_container">
        <div
          class="shadow ___values_card"
          v-for="card in dataEN.cards"
          :key="card.id"
        >
          <img
            :src="require(`../../assets/${card.img}`)"
            alt="Imagen detectar (mobile)"
            loading="lazy"
          />

          <div class="hoverLabel" :style="{ backgroundColor: card.color }">
            <input
              class="form-control form-control-lg wet-form fw-bold text-center mb-2"
              type="text"
              v-model="card.title"
            />

            <textarea
              class="form-control wet-form text-center mb-2"
              rows="5"
              type="text"
              v-model="card.description"
            />
          </div>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-start ms-8">
        <button
          class="btn btn-success btn-lg mt-3 mb-3"
          @click="syncContent('EN')"
        >
          Save
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";

export default {
  name: "Valores",
  data() {
    return {
      dataES: {
        title: "Valores",
        description:
          "Valores que se validan día a día a través de la acción y el comportamiento.",
        cards: [
          {
            id: 1,
            img: "img/valores_img_honestidad.webp",
            title: "Honestidad",
            description: "Cuidamos nuestra hoja de vida y la del emprendedor.",
            color: "#1FAF4B",
          },
          {
            id: 2,
            img: "img/valores_img_transparencia.webp",
            title: "Transparencia",
            description: "Decimos lo que pensamos.",
            color: "#33B0DE",
          },
          {
            id: 3,
            img: "img/valores_img_coherencia.webp",
            title: "Coherencia",
            description: "Hacemos lo que decimos.",
            color: "#FAE53D",
          },
          {
            id: 4,
            img: "img/valores_img_perseverancia.webp",
            title: "Perseverancia",
            description:
              "Apoyamos al emprendedor para que alcance sus sueños. Hacemos todo lo que está a nuestro alcance, en forma obsesiva, para que nuestros clientes realicen las acciones necesarias hasta lograr sus objetivos.",
            color: "#1FAF4B",
          },
          {
            id: 6,
            img: "img/valores_img_compromiso.webp",
            title: "Compromiso y Lealtad",
            description:
              "Nos comprometemos con la empresa como si fuera nuestra.",
            color: "#33B0DE",
          },
          {
            id: 8,
            img: "img/valores_img_agilidad.webp",
            title: "Agilidad Reflexiva",
            description:
              "Sin perder la necesaria agilidad en los tiempos actuales ayudamos a generar la reflexión para colaborar con los emprendedores a prever el impacto de sus decisiones.",
            color: "#FAE53D",
          },
        ],
      },
      dataEN: {
        title: "Valores",
        description:
          "Valores que se validan día a día a través de la acción y el comportamiento.",
        cards: [
          {
            id: 1,
            img: "img/valores_img_honestidad.webp",
            title: "Honestidad",
            description: "Cuidamos nuestra hoja de vida y la del emprendedor.",
            color: "#1FAF4B",
          },
          {
            id: 2,
            img: "img/valores_img_transparencia.webp",
            title: "Transparencia",
            description: "Decimos lo que pensamos.",
            color: "#33B0DE",
          },
          {
            id: 3,
            img: "img/valores_img_coherencia.webp",
            title: "Coherencia",
            description: "Hacemos lo que decimos.",
            color: "#FAE53D",
          },
          {
            id: 4,
            img: "img/valores_img_perseverancia.webp",
            title: "Perseverancia",
            description:
              "Apoyamos al emprendedor para que alcance sus sueños. Hacemos todo lo que está a nuestro alcance, en forma obsesiva, para que nuestros clientes realicen las acciones necesarias hasta lograr sus objetivos.",
            color: "#1FAF4B",
          },
          {
            id: 6,
            img: "img/valores_img_compromiso.webp",
            title: "Compromiso y Lealtad",
            description:
              "Nos comprometemos con la empresa como si fuera nuestra.",
            color: "#33B0DE",
          },
          {
            id: 8,
            img: "img/valores_img_agilidad.webp",
            title: "Agilidad Reflexiva",
            description:
              "Sin perder la necesaria agilidad en los tiempos actuales ayudamos a generar la reflexión para colaborar con los emprendedores a prever el impacto de sus decisiones.",
            color: "#FAE53D",
          },
        ],
      },
    };
  },

  methods: {
    async fetchContent() {
      const section = await getSection("values");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "values",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "values",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<style scoped lang="scss">
hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.moralValues {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  width: 100%;

  .__content {
    max-width: 1366px;
    width: 100%;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .__cards_container {
    display: grid;
    grid-gap: 25px 0;
    grid-template-columns: 1fr;
    grid-auto-rows: max(160px);
    width: 100%;

    .___values_card {
      align-items: center;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
    }

    .___values_card img {
      border-radius: 18px;
      position: absolute;
      max-height: 100%;
      max-width: 100%;
      z-index: -1;
    }

    .___values_card p {
      overflow: hidden;
    }

    .___values_card .hoverLabel {
      align-items: center;
      border-radius: 18px;
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;
      position: absolute;
      padding: 20px;
      top: 0;
      transition: opacity 0.2s ease-out;
      opacity: 1;
      z-index: 1;
    }

    .___values_card .hoverLabel:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

@media (min-width: 992px) {
  .moralValues {
    .__content {
      padding: 65px;
    }

    .__cards_container {
      grid-gap: 26px 23px;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: max(290px);
      margin: 100px 0 0;

      .___values_card {
      }

      .___values_card img {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
